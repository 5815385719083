import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  selectTrades,
} from "./reducers/terminalReducer";
import SymbolTrades from "./components/symbol/SymbolTrades";
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  Paper,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  Collapse,
  Hidden,
  Alert,
} from "@mui/material";
import { addSymbol } from "./reducers/symbolReducer";
import SymbolPriceTradingViewChart from "./components/symbol/SymbolPriceTradingViewChart";
import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  BarChart,
} from "@mui/icons-material";
import SymbolProfit from "./components/symbol/SymbolProfit";
import SymbolMarketCap from "./components/symbol/SymbolMarketCap";
import SymbolDetectionSource from "./components/symbol/SymbolDetectionSource";
import { red, indigo, amber, green } from "@mui/material/colors";
import type { RootState } from "./store";
import { OrderDirection } from "./enums/OrderDirection";

interface ComponentProps {
  symbol: string;
}

const usdtLocaleProfit = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

function TradeContainer(props: ComponentProps) {
  const trades = useSelector(
    (state) => selectTrades(state as RootState, props.symbol),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [showDialog, setShowDialog] = React.useState("");
  
  let openTrades = trades.filter((t) => t.status === "Open");

    // Get the total filled value from all trades
    let totalFilled = trades.filter(t => t.sellingOnAlternateQuote === false).reduce((acc, t) => acc + t.buyValueFilled * (t.contractSize || 1.0) + (t.alternateQuoteBuyValueInUSDT || 0.0) - (t.soldValueFilled || 0.0) , 0);
    totalFilled = trades.filter(t => t.sellingOnAlternateQuote === false).reduce((acc, t) => acc + (t.buyValueFilled * (t.contractSize || 1.0) + (t.alternateQuoteBuyValueInUSDT || 0.0)) * (1.0 - (t.percentSold || 0.0)) , 0);

  const [collapsed, setCollapsed] = React.useState(openTrades.length === 0);

  let direction = trades.length > 0 ? OrderDirection[trades[0].direction].toString().toUpperCase().substring(0, 1) : "L";

  // Find if any trades have a timer that will expire in the next 60 seconds
  let closingSoon = openTrades.filter((t) => {
      let now = new Date();
      let expiry = new Date(t.timedSellTargetDate);
      let diff = expiry.getTime() - now.getTime();
      return diff < 1000 * 60 * 5;
    }).length > 0;

  let tradeWithHighestValue = trades.reduce((prev, current) => (prev.buyValueFilled > current.buyValueFilled) ? prev : current);

  let highestValue = tradeWithHighestValue.buyValueFilled;
  let averagePrice = tradeWithHighestValue.averagePrice;
  let slippage = (tradeWithHighestValue.averagePrice / tradeWithHighestValue.initialPrice) - 1.0;
  let currentPrice = tradeWithHighestValue.currentPrice;
  let roundTrip = (currentPrice / averagePrice) - 1.0;
  let maxMovement = (tradeWithHighestValue.realMaxPrice / averagePrice) - 1.0;

  //let goodEntry = slippage <= 0.015 && roundTrip >= 0.015 && maxMovement >= 0.05;
  let goodEntry = Math.min(0.015, slippage) < roundTrip && maxMovement >= Math.max(0.015, slippage) * 3.0 && openTrades.length > 0;

  //console.log(`Updated trade container ${props.symbol} at ${new Date().valueOf()}`);

  return (
    <>
      <Box key={props.symbol}>
        <Paper
          elevation={3}
          sx={{
            m: 1,
            p: 1,
            border: closingSoon ? `2px solid ${red[500]}` : (goodEntry ? `2px solid ${green[500]}` : null),
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Chip variant="outlined"  label={direction} size="small" sx={{ color: direction === "L" ? indigo[300] : amber[300], borderColor: direction === "L" ? indigo[500] : amber[500], ml: 1, mr: 2, borderRadius: 0, width: 28}} />
              <Typography
                variant="body2"
                sx={{ pl: 1, cursor: "pointer", width: 70 }}
                onClick={() => {
                  dispatch(addSymbol({ symbol: props.symbol, pinned: true }));
                }}
              >
                {props.symbol}
              </Typography>
              
              <Tooltip title="Show chart">
                <IconButton
                  onClick={() => {
                    setShowDialog(props.symbol);
                    dispatch(
                      addSymbol({ symbol: props.symbol, pinned: false })
                    );
                  }}
                >
                  <BarChart fontSize="small" />
                </IconButton>
              </Tooltip>
              <SymbolProfit symbol={props.symbol} />
              <Hidden smDown>
              {
                openTrades.length !== 0
                ? <>
                    <Chip variant="outlined" color={openTrades.length === 0 ? "primary" : "info"} label={usdtLocaleProfit.format(totalFilled)} size="small" sx={{ml: 3, mr: 0, borderRadius: 0, fontWeight: 500 }} />
                    <Chip color={openTrades.length === 0 ? "primary" : "info"} label={openTrades.length === 0 ? "CLOSED" : "OPEN"} size="small" sx={{ml: 0, mr: 2, borderRadius: 0}} />
                </>
                : null
              }
              </Hidden>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
              >
                {collapsed ? (
                  <ArrowDropDownOutlined />
                ) : (
                  <ArrowDropUpOutlined />
                )}
              </IconButton>
            </Box>
          </Box>
          <Collapse in={!collapsed} timeout="auto">
            <Box>
            {
                goodEntry ? <Alert sx={{m:1}} variant="outlined" severity="success" onClose={() => { }}>Your biggest trade <b>(${highestValue.toFixed(0)})</b> has a good entry <b>({((slippage || 0.0) * 100.0).toFixed()}%)</b> and some room <b>({((roundTrip || 0.0) * 100.0).toFixed()}%)</b> to round-trip. Defer timers, cancel TPs, cancel Auto TP etc.</Alert> : null
            }
              <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                  <Box sx={{ display: "flex", pl: 1 }}>
                    <SymbolDetectionSource symbol={props.symbol} />
                  </Box>
                  <Box sx={{ display: "flex", pl: 1 }}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      component="span"
                      sx={{ ml: 0 }}
                    >
                      MCAP:&nbsp;
                    </Typography>
                    <SymbolMarketCap symbol={props.symbol} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={9}>
                  <SymbolPriceTradingViewChart
                    symbol={props.symbol}
                    showEMA={true}
                    height={256}
                    relativeToBuyPrice={true}
                    hideExchangeSelection={true}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <SymbolTrades symbol={props.symbol} openedOnly={true} />
              </Grid>
            </Box>
          </Collapse>
        </Paper>
      </Box>

      <Dialog
        open={showDialog !== ""}
        onClose={() => setShowDialog("")}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <Typography
            variant="body1"
            sx={{ pl: 1, pt: 1, cursor: "pointer" }}
            onClick={() => {
              dispatch(addSymbol({ symbol: showDialog.trim(), pinned: true }));
            }}
          >
            {showDialog.trim()}
          </Typography>
          <SymbolPriceTradingViewChart
            symbol={showDialog.trim()}
            showEMA={true}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default React.memo(TradeContainer);
